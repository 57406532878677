"use client";

import Image from "next/image";
import { useTranslations } from "next-intl";
import React, { forwardRef, memo } from "react";

import { Link, usePathname } from "@/i18n/routing";
import { twClass } from "@/utils/common";

const topics = [
  {
    name: "learn_vietnamese.numbers",
    href: "/learn-vietnamese/numbers",
    imageSrc: "/images/thumbnail-math-learning-helpful-vn.jpg",
    imageAlt: "learn_vietnamese.numbers_alt",
  },
  {
    name: "learn_vietnamese.currency",
    href: "/learn-vietnamese/currency",
    imageSrc: "/images/thumbnail-currency-finance-learning-helpful-vn.jpg",
    imageAlt: "learn_vietnamese.currency_alt",
  },
  {
    name: "learn_vietnamese.greetings",
    href: "/learn-vietnamese/greetings",
    imageSrc: "/images/thumbnail-greetings-communication-helpful-vn.jpg",
    imageAlt: "learn_vietnamese.greetings_alt",
  },
];

type LearnVietnameseTopicProps = {
  className?: string;
};

const LearnVietnameseTopic = forwardRef<HTMLHeadingElement, LearnVietnameseTopicProps>(
  function LearnVietnameseTopic(_, ref) {
    const t = useTranslations();
    const path = usePathname();

    return (
      <>
        <h2 className={twClass("mt-16 text-2xl font-bold tracking-tight text-gray-900")} ref={ref}>
          {t("learn_vietnamese.vietnamese_language_title")}
        </h2>
        <p className="mt-2 text-justify md:text-left">{t("learn_vietnamese.vietnamese_language_description")}</p>

        <div className="mx-auto mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {topics.map((topic, index) => (
            <div
              key={index}
              className={twClass({
                hidden: path === topic.href,
              })}
            >
              <div className="relative">
                <div className="relative h-72 w-full overflow-hidden rounded-lg">
                  <Image
                    alt={t(topic.imageAlt)}
                    width={288}
                    height={288}
                    src={topic.imageSrc}
                    className="size-full object-cover"
                  />
                </div>
                <div className="relative mt-4">
                  <h3 className="text-center text-lg font-semibold text-gray-900 sm:text-left">{t(topic.name)}</h3>
                </div>
              </div>
              <div className="mt-6">
                <Link
                  color="primary"
                  href={topic.href}
                  className="relative flex items-center justify-center rounded-md border border-transparent bg-gray-100 px-8 py-2 text-sm font-medium text-blue-700 hover:bg-gray-200 hover:text-blue-600"
                >
                  {t("learn_vietnamese.learn_now")}
                  <span className="sr-only">, {t(topic.name)}</span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
);

export default memo(LearnVietnameseTopic);
